import React from 'react';
import "../css/HomePage.css";

import linkedin from "../images/linkedin-64.png";
import github from "../images/github-64.png";
import resume from "../images/resume-64.png";
import email from "../images/email-64.png";

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="name">
        <h1>Codey Borrelli</h1>
      </div>
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/codey-borrelli/" target="_blank" rel="noreferrer"><img src={linkedin} alt="LinkedIn" /></a>
        <a href="https://github.com/EpicCoco" target="_blank" rel="noreferrer"><img src={github} alt="GitHub" /></a>
        <a href="https://drive.google.com/file/d/1IBSgubSizaigdpPAE3ulEL-xSyCtrI3Y/view?usp=sharing" target="_blank" rel="noreferrer"><img src={resume} alt="Resume" /></a>
        <a href="mailto:codeyborrelli@gmail.com" target="_blank" rel="noreferrer"><img src={email} alt="Email" /></a>
      </div>
    </div>
  );
};

export default HomePage;
